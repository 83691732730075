import {useEnv} from '~/helpers/env';
import {useNuxtApp} from '#imports';

export function useHelpers() {
  const getPriceLabel = (price) => {
    const nuxtApp = useNuxtApp();
    return price > 0
      ? nuxtApp.vueApp.config.globalProperties.$filters.currency(price)
      : 'Free';
  };

  const getPriceRange = (editions) => {
    let min = null;
    let max = null;

    for (let i = 0; i < editions.length; i++) {
      const edition = editions[i];

      let price = 0;

      if (edition.price) {
        price = parseInt(edition.price);
      }

      if (min === null) {
        min = price;
      }

      if (max === null) {
        max = price;
      }

      if (price < min) {
        min = price;
      }

      if (price > max) {
        max = price;
      }
    }

    return {
      min,
      max,
    };
  };

  const getPriceRangeLabel = (plugin) => {
    const {min, max} = getPriceRange(plugin.editions);

    if (min !== max) {
      return `${getPriceLabel(min)} – ${getPriceLabel(max)}`;
    }

    return getPriceLabel(min);
  };

  const isPluginFree = (plugin) => {
    const {min, max} = getPriceRange(plugin.editions);

    if (min !== 0 || max !== 0) {
      return false;
    }

    return true;
  };

  const getPluginBuyUrl = (plugin, edition) => {
    const {craftIdUrl} = useEnv();
    if (plugin.abandoned) {
      return null;
    }

    return craftIdUrl + '/buy-plugin/' + plugin.handle + '/' + edition.handle;
  };

  return {
    getPriceLabel,
    getPriceRange,
    getPriceRangeLabel,
    isPluginFree,
    getPluginBuyUrl,
  };
}

export default useHelpers();
